import {Actions}      from "../types/Actions";
import {ActionType}   from "../constants/ActionType";
import {initialState} from "../index";

export default (state = initialState.view, action:Actions) => {
    switch (action.type) {
        case ActionType.UNAUTHORIZED :
            return {
                ...state,
                unauthorized:true
            };
        case ActionType.ERROR_MESSAGE :
            return {
                ...state,
                errorMessage:action.payload
            };
        case ActionType.UPDATE_METADATA :
            return {
                ...state,
                metadata:{
                    ...state.metadata,
                    ...action.payload
                }
            };
        case ActionType.LOGOUT :
            return {
                ...initialState.view
            };
        case ActionType.SHOW_LOADER :
            return {
                ...state,
                loading:true
            };
        case ActionType.NOTIFY_ERROR :
        case ActionType.ERROR :
        case ActionType.HIDE_LOADER :
            return {
                ...state,
                loading:false
            };
        case ActionType.SHOW_INCOME_HELP_MODAL :
            return {
                ...state,
                incomeHelpModal:true
            };
        case ActionType.HIDE_INCOME_HELP_MODAL :
            return {
                ...state,
                incomeHelpModal:false
            };
        case ActionType.INIT_LIVE_AGENT :
            return {
                ...state,
                live_agent:true
            };
        case ActionType.OPEN_IAV :
        case ActionType.CLOSE_IAV :
            return {
                ...state,
                iav_url:action.payload
            };
        case ActionType.CTS_LOADED:
            return {
                ...state,
                cts_loaded:true
            };
        default:
            return state;
    }
}