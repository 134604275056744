export enum ApplicantStatus {
    NO_LOGIN						                = 'No Login',
    APP_INCOMPLETE					                = 'App Incomplete',
    PERSONAL_INFO					                = 'Progress: Personal Info',
    INCOME					                        = 'Progress: Income',
    FINANCES					                    = 'Progress: Finances',
    CO_BORROWER					                    = 'Progress: Coborrower',
    ASSETS					                        = 'Progress: Assets',
    DOCS_NO_APP						                = 'Docs No App',
    MISSING_DOCS					                = 'Missing Docs',
    APPLICATION_AND_DOCS_RECEIVED	                = 'Application and Docs Received',
    MISSING_DOCS_IAV	                            = 'Missing Docs - IAV',
    MISSING_DOCS_IAV_AND_MANUAL	                    = 'Missing Docs - IAV and Manual',
    APPLICATION_AND_DOCS_RECEIVED_IAV	            = 'Application and Docs Received - IAV',
    APPLICATION_AND_DOCS_RECEIVED_IAV_AND_MANUAL	= 'Application and Docs Received - IAV and Manual',
}