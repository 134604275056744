import * as React from 'react';
import { Component } from './component';
import {classNames} from "./utils";
import {HTMLAttributes} from "react";

export class Block extends  Component<HTMLAttributes<HTMLDivElement>>{
    render(){
        return <div {...this.rest()} className={classNames(this.props.className, this.generalClassNames())}/>
    }
}
export class Inline extends Component<HTMLAttributes<HTMLSpanElement>>{
    render(){
        return <span {...this.rest()} className={classNames(this.props.className, this.generalClassNames())}/>;
    }
}