import { Applicant } from "../types/models/Applicant";
import { Store } from "redux";
import { State } from "../types/State";
import * as tokne from "../selectors/token";
import * as browser from "../selectors/browser";
import * as data from "../selectors/data";
import { logout, unauthorized } from "../actions/view";
import { IavSession } from "../types/iav/IavSession";
import { Person } from "../types/iav/Person";
import { Dms } from "../types/models/Dms";
import { TokenResponse } from "../types/models/TokenResponse";
import { ApplicantStatus } from "../constants/ApplicantStatus";
import { ApplicantType } from "../constants/ApplicantType";
import { Application } from "../types/models/Application";
import { EcmDocumentType } from "../types/ecm";

export class Api {
  prefix: string;

  constructor(protected store?: Store<State>) {
    this.prefix = "/acceleration-loan/information";
  }

  setStore(store: Store<State>) {
    this.store = store;
  }

  protected getApplicationId() {
    return data.getLoanApplicationId(this.store.getState());
  }

  async fetchJson(input: RequestInfo, init: RequestInit = {}) {
    const state = this.store.getState();
    const { access_token, token_type } = tokne.getToken(state);
    const sessionId = browser.getSessionId(state);
    const browserId = browser.getBrowserId(state);
    const loginId = browser.getLoginId(state);
    const headers = new Headers({
      ...init.headers,
      Accept: "application/json",
      "Content-Type": "application/json",
    });
    if (access_token) {
      headers.set("Authorization", `${token_type} ${access_token}`);
    }
    if (sessionId) {
      headers.set("x-browser-id", browserId);
      headers.set("x-session-id", sessionId);
    }
    if (loginId) {
      headers.set("x-login-id", loginId);
    }
    let response = await fetch(`${this.prefix}${input}`, {
      ...init,
      headers: headers,
    });
    const json = await response.json();
    if (response.ok) {
      return json;
    }
    if (response.status === 401) {
      this.store.dispatch(unauthorized());
      this.store.dispatch(logout());
    }
    throw json;
  }

  async putApplicantStatus(
    applicant_status: ApplicantStatus,
    applicant_type: ApplicantType = null
  ) {
    return await this.fetchJson(`/api/application/applicant-status`, {
      method: "PUT",
      body: JSON.stringify({ applicant_status, applicant_type }),
    });
  }

  async postFile(body: FormData) {
    const state = this.store.getState();
    const { access_token, token_type } = tokne.getToken(state);
    const sessionId = browser.getSessionId(state);
    const browserId = browser.getBrowserId(state);
    const loginId = browser.getLoginId(state);
    const headers = new Headers();
    if (access_token) {
      headers.set("Authorization", `${token_type} ${access_token}`);
    }
    if (sessionId) {
      headers.set("x-browser-id", browserId);
      headers.set("x-session-id", sessionId);
    }
    if (loginId) {
      headers.set("x-login-id", loginId);
    }
    let response = await fetch(
      `${this.prefix}/api/ecm/applicant/${
        data.getCurrentApplicant(this.store.getState()).cplus_person_id
      }/document`,
      {
        method: "POST",
        body: body,
        headers: headers,
      }
    );
    const json = await response.json();
    if (response.ok) {
      return json;
    }
    throw json;
  }

  async postAdditionalDocuments(data) {
    return await this.fetchJson(`/api/application/additional-documents`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(data),
    });
  }

  async postNotifySalesforce() {
    return await this.fetchJson(`/api/application/notify-salesforce`, {
      method: "POST",
    });
  }

  async postNotifyError(data) {
    return await this.fetchJson(`/api/notify-error`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async postApplyApplication(data) {
    return await this.fetchJson(`/api/application/apply`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async putDocuments(dms): Promise<Dms> {
    return await this.fetchJson(`/api/application/documents`, {
      method: "PUT",
      body: JSON.stringify(dms),
    });
  }

  async createPerson(person: Applicant): Promise<Person> {
    return await this.fetchJson(`/api/iav/person`, {
      method: "POST",
      body: JSON.stringify(person),
    });
  }

  async createIavSession(
    personId: number,
    language: string
  ): Promise<IavSession> {
    return await this.fetchJson(`/api/iav/session/${personId}/${language}`, {
      method: "POST",
    });
  }

  async createIavReport(personId) {
    return await this.fetchJson(`/api/iav/person/${personId}/report`, {
      method: "POST",
      body: JSON.stringify({ loan_application_id: this.getApplicationId() }),
    });
  }

  async updateAdditionalProperties(body) {
    return await this.fetchJson(`/api/application/additional-properties`, {
      method: "PUT",
      body: JSON.stringify(body),
    });
  }

  async authorize(token: string, lapstone = false): Promise<TokenResponse> {
    return await this.fetchJson(`/api/token`, {
      method: "POST",
      body: JSON.stringify({
        token,
        lapstone,
      }),
    });
  }

  async getDocumentTypes(): Promise<EcmDocumentType[]> {
    return await this.fetchJson(`/api/ecm/types`);
  }

  async completeIav({ dms, applicant_status }): Promise<Application> {
    return await this.fetchJson(`/api/application/iav/complete`, {
      method: "PATCH",
      body: JSON.stringify({ dms, applicant_status }),
    });
  }
}
