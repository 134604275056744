import *  as React              from "react";
import {PureComponent,Fragment} from 'react';
import {Modal}                  from "../modals/Modal";
import {connect}                from "react-redux";
import {getMeaningfulError}     from "../../store/selectors/error";
import {notifyError}            from "../../store/actions/error";
import {State}                  from "../../store/types/State";
import {NotifyError}            from "../../store/types/Actions";


export class ErrorAlert extends PureComponent<{
    meaningfulError:string,
    clearError:()=>NotifyError
}>{
    render(){
        const { clearError,meaningfulError } = this.props;
        return (
            <Modal id="error" className="error" onClose={clearError} closeOnBackgroundClick={false}  isSmall={true} header="Error">
                {meaningfulError ?
                    <div className="text-center subheader font-16">
                        <strong>{meaningfulError}</strong>
                    </div>:
                    <Fragment>
                        <h1 className="text-center">Whoops, looks like something went wrong.</h1>
                        <h5 className="text-center subheader">Please try again later.</h5>
                    </Fragment>
                }
            </Modal>
        )
    }
}

export default connect((state:State)=>({
    meaningfulError:getMeaningfulError(state)
}),{
    clearError:()=>notifyError({})
})(ErrorAlert)