import *  as React                        from "react";
import {PureComponent}                    from "react";
import {Column, Reveal, RevealProps, Row} from "../foundation";

export interface ModalProps extends RevealProps{
    header?:string|any
}

export class Modal extends PureComponent<ModalProps>{
    render(){
        let {
            header,
            children,
            ...rest
        } = this.props;
        return (
            <Reveal closeIcon="Close" {...rest}>
                <div className="modal-header">
                    <Row>
                        <Column small={12}>
                            {header}
                        </Column>
                    </Row>
                </div>
                <div className="body">
                    {children}
                </div>
            </Reveal>
        )
    }
}