import * as React from 'react';
import { Component } from './component';
import {classNames} from "./utils";
import {utag} from "../../helpers/utils/utag";

export enum BUTTON_SIZE {
    TINY  = 'tiny',
    SMALL = 'small',
    LARGE = 'large'
}
export enum COLORS {
    PRIMARY     = 'primary',
    SECONDARY   = 'secondary',
    SUCCESS     = 'success',
    ALERT       = 'alert',
    WARNING     = 'warning',
    FOURTHLY    = 'fourthly'
}
export type ButtonType = React.ButtonHTMLAttributes<HTMLButtonElement>|React.AnchorHTMLAttributes<HTMLAnchorElement>;
export interface ButtonProps {
    id:string,
    color?: COLORS,
    size?:BUTTON_SIZE,
    isHollow?:boolean,
    isExpanded?:boolean,
    isDisabled?:boolean,
    disabled?:boolean,
    isDropdown?:boolean,
    isArrowOnly?:boolean,
    full?:boolean,
    anchor?: boolean,
    hasTealium?: boolean,
    buttonText?: string,
    ga_action?: string,
    ga_category?: string,
    ga_label?: string,
    ga_page_event?: string,
}

export class Button extends Component<ButtonProps&ButtonType>{

    classNames():string{
        return classNames(
             'button',
            this.props.className,
            this.props.size,
            this.props.color,
            {
                'hollow'    : this.props.isHollow,
                'expand'    : this.props.isExpanded,
                'disabled'  : this.props.isDisabled||this.props.disabled,
                'dropdown'  : this.props.isDropdown,
                'arrow-only': this.props.isArrowOnly,
                'full'      : this.props.full,
            },
            this.generalClassNames()
        );
    }
    protected rest(){
        const {
            color,
            size,
            isHollow,
            isExpanded,
            isDisabled,
            isDropdown,
            anchor,
            full,
            isArrowOnly,
            buttonText,
            hasTealium,
            ...restProps
        } = super.rest();
        return restProps;
    }

    onClickWrapper = (e) => {
        const { onClick, id, ga_action, ga_category, ga_label, ga_page_event, buttonText } = this.props
        utag().link({
            click_id: id || '',
            click_text: buttonText || '',
            tealium_event: 'button_click',
            ...(ga_action ? { ga_action } : {}),
            ...(ga_category ? { ga_category } : {}),
            ...(ga_label ? { ga_label } : {}),
            ...(ga_page_event ? { ga_page_event } : {}),
        });
        if (onClick) onClick(e);
    }

    render(){
        // eslint-disable-next-line
        return !this.props.anchor ?  (
            <button
                {...this.rest()}
                onClick={this.props.hasTealium ? this.onClickWrapper : this.props.onClick}
                disabled={this.props.isDisabled||this.props.disabled}
                className={this.classNames()}
            />
        ) : (
            // TODO: Possible security issue
            // eslint-disable-next-line
            <a href="javascript:;" {...this.rest()} className={this.classNames()} />
        )
    }
}


