import * as React from "react";
import { PureComponent } from "react";
import { classNames } from "./utils";

export interface AlertProps {
  warning?: boolean;
  alert?: boolean;
  className?: string;
  children?: any;
}
export interface AlertState {
  close?: boolean;
}

export class Alert extends PureComponent<AlertProps, AlertState> {
  state = {
    close: false,
  };
  render() {
    const { alert, warning } = this.props;
    if (this.state.close) {
      return null;
    }
    return (
      <div
        className={classNames("alert-box", this.props.className, {
          warning,
          alert,
        })}
      >
        {this.props.children}
        {/* AAL-TODO: TEST THE REMOVAL OF `href="javascript:;"`
            TODO: TEST THE REMOVAL OF `href="javascript:;"` */}
        {/*eslint-disable-next-line*/}
        <a onClick={() => this.setState({ close: true })} className="close">
          &times;
        </a>
      </div>
    );
  }
}
