export const phoneFormats = {
    DOMESTIC : { regex : /([0-9]{3})([0-9]{3})([0-9]{4})/, replacement : "($1) $2-$3"},
    INTERNATIONAL : { regex : /([0-9]{3})([0-9]{3})([0-9]{4})/, replacement : "1-$1-$2-$3"},
    CORE : { regex : /([0-9]{3})([0-9]{3})([0-9]{4})/, replacement : "$1-$2-$3"}
};
export const phoneFormat = (phone,format?)=>{
    format = format ? format : 'DOMESTIC';
    return String(phone)
        .replace(/\D/g, "")
        .replace(phoneFormats[format]['regex'],phoneFormats[format]['replacement']);
};