import {createSelector} from "reselect";
import {State}          from "../types/State";

export const getBrowser = (state:State) => state.browser;

export const getSessionId = createSelector(
    getBrowser,
    (browser)=>browser.session_id
);

export const getBrowserId = createSelector(
    getBrowser,
    (browser)=>browser.browser_id
);

export const getLoginId = createSelector(
    getBrowser,
    (browser)=>browser.login_id
);