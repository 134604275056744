import {ActionType}      from "../constants/ActionType";
import {initialState}    from "../index";
import {Actions}         from "../types/Actions";
import {browserId, guid} from "../../helpers/utils/guid";

export default (state = initialState.browser, action: Actions) => {
    switch (action.type) {
        case ActionType.INITIALIZE :
            return !state.session_id ? {
                ...state,
                browser_id: browserId(),
                session_id: guid()
            } : state;
        case ActionType.SET_VT_ID :
            return {
                ...state,
                vt_session_id: action.payload
            };
        case  ActionType.INIT :
            return {
                ...state,
                login_id: guid()
            };
        default:
            return state;
    }
}