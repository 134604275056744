import {ActionType}          from "../constants/ActionType";
import {include}             from "../../helpers/utils/include";
import {getCurrentApplicant} from "../selectors/data";
import {ThunkAction}         from "../middlewares/thunk";
import {isAuthenticated}     from "../selectors/token";

export const initEsw = (): ThunkAction<ActionType.INIT_LIVE_AGENT> => {
    return async (dispatch, getState) => {
        const config = window.config;
        const applicant = isAuthenticated(getState()) ? getCurrentApplicant(getState()) : {};
        const user = Object(applicant);
        const { assetsPath } = config;
        await include('https://service.force.com/embeddedservice/5.0/esw.min.js');
        const { embedded_svc } = window;
        embedded_svc.settings.displayHelpButton = true;
        embedded_svc.settings.language = 'en-US';
        //embedded_svc.settings.defaultMinimizedText = 'Help';

        embedded_svc.settings.prepopulatedPrechatFields = {
            FirstName: user.first_name,
            LastName: user.last_name,
            Email: user.email,
            PreChatSurvey__c: "I have questions about Achieve Acceleration Loans"
        };
        embedded_svc.settings.extraPrechatInfo = [{
            "entityFieldMaps": [{
                "doCreate": false,
                "doFind": true,
                "fieldName": "ApplicantsEmail__c",
                "isExactMatch": true,
                "label": "Email"
            },
                {
                    "doCreate": false,
                    "doFind": true,
                    "fieldName": "IsClosed",
                    "isExactMatch": true,
                    "label": "IsClosed"
                }],
            "entityName": "Opportunity",
            "linkToEntityName": "Opportunity",
            "linkToEntityField": "OpportunityId__c",
            "saveToTranscript": "OpportunityId__c"
        },
            {
                "entityFieldMaps": [{
                    "doCreate": false,
                    "doFind": false,
                    "fieldName": "LastName",
                    "isExactMatch": true,
                    "label": "Last Name"
                }, {
                    "doCreate": false,
                    "doFind": false,
                    "fieldName": "FirstName",
                    "isExactMatch": true,
                    "label": "First Name"
                }, {
                    "doCreate": false,
                    "doFind": false,
                    "fieldName": "Email",
                    "isExactMatch": true,
                    "label": "Email"
                }],
                "entityName": "Contact",
                "showOnCreate": false
            }];

        embedded_svc.settings.extraPrechatFormDetails = [{
            "label": "First Name", "transcriptFields":
                ["FirstName__c"]
        }, {
            "label": "Last Name", "transcriptFields":
                ["LastName__c"]
        }, {
            "label": "Email", "transcriptFields":
                ["Email__c"]
        }, {
            "label": "Need help with", "transcriptFields":
                ["PreChatSurvey__c"]
        }, { "label": "IsClosed", "value": "false", "displayToAgent": false }];

        embedded_svc.settings.prechatBackgroundImgURL = `${assetsPath === '/' ? window.location.origin : assetsPath}/images/LiveAgentChatBanner.jpeg`;
        embedded_svc.settings.widgetWidth = '390px';
        embedded_svc.settings.enabledFeatures = ['LiveAgent'];
        embedded_svc.settings.entryFeature = 'LiveAgent';

        const {
            baseCoreURL,
            communityEndpointURL,
            gslbBaseURL,
            orgId,
            eswConfigDevName,
            baseLiveAgentContentURL,
            deploymentId,
            buttonId,
            baseLiveAgentURL,
            eswLiveAgentDevName,
            isOfflineSupportEnabled
        } = config.liveAgent;
        embedded_svc.init(baseCoreURL, communityEndpointURL, gslbBaseURL, orgId, eswConfigDevName, {
            baseLiveAgentContentURL,
            deploymentId,
            buttonId,
            baseLiveAgentURL,
            eswLiveAgentDevName,
            isOfflineSupportEnabled
        });
        dispatch({ type: ActionType.INIT_LIVE_AGENT });
    }
};

export const initLiveAgent = (): ThunkAction => {
    return async (dispatch, getState) => {
        const state = getState();
        try {
            if (state.view.live_agent) {
                return;
            }
            if (window.liveAgent) {
                dispatch(initEsw());
            }
        } catch (e) {
            console.warn(e);
        }
    }
};
