const DEFAULT_POLLING_TIMEOUT = 200;
const DEFAULT_NUM_TRIES = 50;
const DEFAULT_UTAG = { link: () => {}, view: () => {} }

export const utag = () => window.utag || DEFAULT_UTAG;

export const withUtag = async () => {
    if (window.utag) return window.utag;
    let i = 0;
    while (i < DEFAULT_NUM_TRIES) {
        await new Promise(resolve => setTimeout(resolve, DEFAULT_POLLING_TIMEOUT));
        if (window.utag) return window.utag;
        i = i + 1;
    }
    return DEFAULT_UTAG;
}