import {createSelector} from "reselect";
import {State}          from "../types/State";

export const getError = (state: State) => state.error;

export const hasError = createSelector(
    getError,
    (error) => {
        if (error['indicator'] === 'warning') {
            return false;
        }
        return error && (!!Object.keys(error).length || error instanceof Error);
    }
);
export const getMeaningfulError = createSelector(
    hasError,
    getError,
    (isError, error) => {
        if (!isError) return;
        if (error instanceof Error) {
            return;
        }
        if (error.translated) {
            return error.message;
        }
        return void 0;
    }
);
export const isMeaningfulError = (error) => {
    if (typeof error !== 'object') {
        return false;
    }
    return !!error.message && (error.translated || error.indicator === 'warning');
};

