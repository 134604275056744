import {ActionType}   from "../constants/ActionType";
import {initialState} from "../index";
import {Actions}      from "../types/Actions";

export default (state = initialState.error, action: Actions) => {
    switch (action.type) {
        case ActionType.NOTIFY_ERROR :
        case ActionType.ERROR :
            return action.payload;
        default:
            return state;
    }
}