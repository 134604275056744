import * as React from "react";
import { PureComponent } from "react";
import { Header } from "./Header";
import { Main } from "../elements/Main";
import { Footer } from "./Footer";
import { Column, Row } from "../foundation";

export class Layout extends PureComponent<{
  className?: string;
  footer?: boolean;
  children?: any;
}> {
  static defaultProps = {
    footer: true,
  };

  render() {
    const { footer } = this.props;
    return (
      <div className="off-canvas-wrap">
        <Header />
        <Main parentClass={this.props.className}>
          <Row>
            <Column medium={10} small={12} centerOnSmall={true}>
              {this.props.children}
            </Column>
          </Row>
        </Main>
        {footer && <Footer />}
      </div>
    );
  }
}
