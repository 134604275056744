import {ActionType}               from "../constants/ActionType";
import {CreateError, NotifyError} from "../types/Actions";

export const notifyError = (error): NotifyError => ({
    type: ActionType.NOTIFY_ERROR,
    payload: error
});
export const error = (error): CreateError => ({
    type: ActionType.ERROR,
    payload: error
});