import *  as React     from "react";
import {PureComponent} from "react";
import {classNames}    from "../foundation";

export class Main extends PureComponent<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>&{parentClass?:string}>{
    render(){
        const {parentClass,...props} = this.props;
        return (
            <div className={classNames('main clearfix',parentClass)} >
                <div className="main-content" {...props} />
            </div>
        )
    }
}