export async function include(url) {
  return await new Promise((accept: any, reject) => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    script.type = "application/javascript";
    script.onload = function () {
      accept();
    };
    script.onerror = function () {
      reject();
    };
    document.head.appendChild(script);
  });
}
