import {initialState} from "../index";
import {ActionType}   from "../constants/ActionType";
import {Actions}      from "../types/Actions";
import {Application}  from "../types/models/Application";


export default (state = initialState.data, action: Actions):Application => {
    switch (action.type) {
        case ActionType.INIT:
            return {
                ...state,
                ...action.payload.data
            };
        case ActionType.LOGOUT :
            return initialState.data;
        case ActionType.UPDATE_APPLICANT_STATUS :
            return {
                ...state,
                application_details: {
                    ...state.application_details,
                    primary_applicant_status: action.payload
                },
            };
        case ActionType.UPDATE_COAPPLICANT_STATUS :
            return {
                ...state,
                application_details: {
                    ...state.application_details,
                    coapplicant_status: action.payload
                },
            };
        case ActionType.UPDATE_ADDITIONAL_PROPERTIES :
            return {
                ...state,
                additional_properties: {
                    ...(state.additional_properties || {}),
                    ...action.payload
                }
            };
        case ActionType.UPDATE_DOCUMENT_MANAGEMENT_SYSTEM :
            return {
                ...state,
                dms: {
                    ...state.dms,
                    ...action.payload
                }
            };
        case ActionType.UPDATE_APPLICATION :
        case ActionType.SYNC_APPLICATION :
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}