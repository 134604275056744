import 'components-font-awesome/scss/font-awesome.scss';
import './index.scss';
import * as React                     from 'react';
import {lazy}                         from 'react';
import {render}                       from "react-dom";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Provider}                     from "react-redux";
import {LazyRoute}                    from "./components/route/LazyRoute";
import {configureStore}               from "./store";
import {ThankYou}                     from "./modules/docs/components/ThankYou";
import {Layout}                       from "./components/sections/Layout";
import Home                           from "./modules/home/App";
import Maintenance                    from "./modules/maintenance/App"


export async function main() {
    const store = configureStore();
    render(
        <Provider store={store}>
            <BrowserRouter basename="/acceleration-loan/information">
                <Switch>
                    {window.config.maintenance_mode &&  <Route path={'/'} component={Maintenance}/> }
                    <Route path={'/finicity/thank-you'} render={() => (
                        <Layout>
                            <ThankYou/>
                        </Layout>
                    )}/>
                    <LazyRoute path={'/lpflow'} component={lazy(() => import('./modules/internal/App'))}/>
                    <LazyRoute path={'/docs_additional'} component={lazy(() => import('./modules/additional/App'))}/>
                    <LazyRoute path={'/docs_internal'} component={lazy(() => import('./modules/internal/App'))}/>
                    <LazyRoute path={'/docs'} component={lazy(() => import('./modules/docs/App'))}/>
                    <LazyRoute path={'/application'} component={lazy(() => import('./modules/application/App'))}/>
                    <Route path={'/'} component={Home}/>
                </Switch>
            </BrowserRouter>
        </Provider>,
        document.getElementById("root")
    );
}

main().catch(console.error);


