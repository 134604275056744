import {ActionType}   from "../constants/ActionType";
import {initialState} from "../index";
import {Actions}      from "../types/Actions";

export default (state = initialState.token, action: Actions) => {
    switch (action.type) {
        case ActionType.INIT:
            const { data, ...payload } = action.payload;
            return {
                ...state,
                ...payload
            };
        case ActionType.SET_CURRENT_APPLICANT:
            return {
                ...state,
                lapstone_applicant_id:action.payload
            };
        case ActionType.REFRESH_TOKEN:
            return {
                ...state,
                ...action.payload
            };
        case ActionType.SET_PERSON_ID:
            return {
                ...state,
                person_id:action.payload
            };
        case ActionType.LOGOUT :
            return initialState.token;
        default:
            return state;
    }
}