export function guid() {
    return Math.round(Math.random()*(Number.MAX_SAFE_INTEGER||Date.now())).toString()+Date.now()
}

export function browserId() {
    let nav = window.navigator;
    let screen = window.screen;
    let guid = String(nav.mimeTypes.length);
    guid += nav.userAgent.replace(/\D+/g, '');
    guid += nav.plugins.length;
    guid += screen.height || '';
    guid += screen.width || '';
    guid += screen.pixelDepth || '';
    return guid;
}
