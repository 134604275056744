import {initialState}   from "../index";
import {Actions}        from "../types/Actions";
import {ActionType}     from "../constants/ActionType";

export default (state = initialState.ecm,action: Actions) => {
    switch (action.type){
        case ActionType.DOC_TYPES:
            return {
                ...state,
                types:action.payload
            };
        default:
            return state;
    }

}