import {withUtag} from "./utag";
import {hashEmail} from "./hashUtils";

export const tealiumFlowStep = (flowStepName: string, email: string) => {
    withUtag().then(utag => {
        try {
            utag.view({
                tealium_event: 'flow_step',
                flow_step_name: flowStepName,
                user_email_hash: hashEmail(email),
            });
        } catch (err) {
            utag.view({
                tealium_event: 'flow_step',
                flow_step_name: flowStepName,
                user_email_hash: 'couldnthashemail',
            });
        }
    });
}

export const tealiumFlowComplete = (email: string) => {
    withUtag().then(utag => {
        try {
            utag.view({
                tealium_event: 'flow_complete',
                user_email_hash: hashEmail(email),
            });
        } catch (err) {
            utag.view({
                tealium_event: 'flow_complete',
                user_email_hash: 'couldnthashemail',
            });
        }
    });
}