import {State}            from "../types/State";
import {Store}            from "redux";
import {EventType}        from "../constants/EventType";
import * as tokenSelector from "../selectors/token";
import * as viewActions   from "../actions/view";
import {setVTId}          from "../actions/browser";

export class Tracker {

    static currentPageview;

    static createSession = ({ location, document, screen }, params = Tracker.getParams()) => ({
        host_name: location.hostname,
        utm_http_referer: document.referrer,
        experiment_id: null,
        variation_id: null,
        created_at: null,
        updated_at: null,
        screen_size: screen.width + 'x' + screen.height,
        session_id: params.vt_session_id || undefined,
        visitor_id: params.vt_visitor_id || undefined,
    });

    static createEvent = (data, { location }) => ({
        session_id: data.session_id,
        parent_event_id: null,
        lead_id: data.lead_id,
        page_url: data.page_url || location.href,
        page_name: data.page_name || location.protocol + '//' + location.host + location.pathname,
        event_page: data.event_page || location.pathname,
        event_action: data.event_action,
        event_element: data.event_element,
        event_element_name: data.event_element_name,
        event_value: data.event_value,
        event_id: null,
        additional_properties: data.additional_properties || {},
        time_difference: null,
        createdAt: new Date(),
    });

    static getParams = () => {
        const sessionToSend: any = {};
        const splitByAmpersand = window.location.search.substring(1).split('&');

        for (let i = 0; i < splitByAmpersand.length; i++) {
            let pair = splitByAmpersand[i].split('=');
            let key = pair[0];
            sessionToSend[key] = pair[1];
        }
        return sessionToSend;
    };

    protected session: ReturnType<typeof Tracker.createSession>;

    protected baseUrl: string;

    private started: boolean = false;

    constructor(protected store?: Store<State>) {
        this.session = Tracker.createSession(window);
        const config = window.config;
        this.baseUrl = config && config.vt_url ?
            config.vt_url
            : 'https://visitortracking.staging.billsdev.com/visitortracking/';
    }

    public setStore(store: Store<State>) {
        this.store = store;
    }

    public isStarted(){
        return this.store && this.started;
    }

    generateEvent(eventData, parentEvent = Tracker.currentPageview) {
        const state = this.store.getState();
        const session = this.session;
        const isLoggedIn = tokenSelector.isAuthenticated(state);
        const { data } = state;
        let event = Tracker.createEvent({
            ...eventData,
            session_id: session.session_id,
        }, window);

        if (!session.session_id) {
            setTimeout(async () => {
                await this.trackEvent(event, parentEvent);
            }, 100);
            return;
        }

        if (event.event_action === EventType.PAGEVIEW) {
            Tracker.currentPageview = event;
        } else if (parentEvent) {
            if (!parentEvent.event_id) {
                setTimeout(async () => {
                    await this.trackEvent(event, parentEvent);
                }, 100);
                return;
            }
            event.parent_event_id = parentEvent.event_id;
        }
        if (isLoggedIn) {
            event.additional_properties = {
                ...(event.additional_properties || {}),
                loan_application_id: data && data.loan_application.loan_application_id
            };
        }
        return event;
    };

    async start() {
        if( this.isStarted() ){
            return;
        }
        this.started = true;
        const state = this.store.getState();

        // Use current session id if available
        try {
            if (state.browser.vt_session_id) {
                this.session.session_id = state.browser.vt_session_id;
            }
        } catch (error) {
            console.error(error);
        }
        //add click event on buttons
        document.addEventListener('click', (e) => {
            const element = e.target as Element;
            switch (element && element.id && element.tagName) {
                case 'A':
                case 'BUTTON':
                    if (!!element.getAttribute('data-prevent-track')) {
                        break;
                    }
                    this.store.dispatch(viewActions.clickElement(e));
                    break;
            }
        });
        const data = await this.postSession(this.session);
        // Add session details from server
        this.session = {
            ...this.session,
            session_id: data.session.session_id,
            visitor_id: data.session.visitor_id,
            created_at: data.session.created_at,
            updated_at: data.session.updated_at,
        };
        this.store.dispatch(setVTId(this.session.session_id));
    };

    public stop(){
        this.started = false;
    }

    async trackEvent(eventData, parentEvent = Tracker.currentPageview){
        if( !this.isStarted() ){
            return;
        }
        const event = this.generateEvent(eventData, parentEvent);
        const session = this.session;
        if (event) {
            const result = await this.postEvent(event, { session });
            event.event_id = result.event_id;
            return event;
        }
    };

    async postSession(session) {
        const isPost = typeof session.session_id === 'undefined';

        let url = `${this.baseUrl}session`;
        if (!isPost) {
            url = `${url}/${session.session_id}`;
        }

        const response = await fetch(url, {
            method: isPost ? 'POST' : 'PUT',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors',
            body: JSON.stringify(session),
        });

        return await response.json();
    };

    async postEvent(event, { session }) {
        const currentTime = new Date();
        const input = `${this.baseUrl}events/session/${session.session_id}`;
        const init: RequestInit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors',
            body: JSON.stringify({
                ...event,
                time_difference: currentTime.getTime() - event.createdAt.getTime(),
            }),
        };
        const response = await fetch(input, init);
        return await response.json();
    };
}