import {
    ErrorMessage,
    HideIncomeHelpModal, HideLoader, Logout,
    ShowIncomeHelpModal,
    ShowLoader,
    UnauthorizedResponse,
    UpdateMetadata
} from "../types/Actions";
import {ActionType} from "../constants/ActionType";
import {EventType}  from "../constants/EventType";
import {Location}   from 'history';

export const showLoader = (): ShowLoader => ({
    type: ActionType.SHOW_LOADER
});
export const hideLoader = (): HideLoader => ({
    type: ActionType.HIDE_LOADER
});
export const logout = (): Logout => ({
    type: ActionType.LOGOUT
});
export const unauthorized = (): UnauthorizedResponse => ({
    type: ActionType.UNAUTHORIZED
});
export const metadata = (data: { [k: string]: any }): UpdateMetadata => ({
    type: ActionType.UPDATE_METADATA,
    payload: data
});
export const errorMessage = (message: string): ErrorMessage => ({
    type: ActionType.ERROR_MESSAGE,
    payload: message
});
export const showIncomeHelpModal = (): ShowIncomeHelpModal => ({
    type: ActionType.SHOW_INCOME_HELP_MODAL
});
export const closeIncomeHelpModal = (): HideIncomeHelpModal => ({
    type: ActionType.HIDE_INCOME_HELP_MODAL
});
export const clickCheckbox = (e, eventValue) => {
    return (dispatch) => {
        e.target.event_value = String(eventValue);
        dispatch(clickElement(e));
    }
};
export const clickElement = (e) => ({
    type: ActionType.CLICK_ELEMENT,
    payload: {
        event_element: e.target.nodeName.toLowerCase(),
        event_element_name: e.target.id,
        event_value: e.target.event_value || e.target.innerText
    },
});
export const pageView = (location?: Location | string) => ({
    type: EventType.PAGEVIEW,
    payload: location || window.location.pathname
});

