import *  as React   from "react";
import {Component}   from "react";
import {Column, Row} from "../foundation";
import { utag } from "../../helpers/utils/utag";

export class Header extends Component {

    render() {
        return (
            <div className="contain-to-grid apply-header" id="header">
                <div className="shadow-wrapper">
                    <nav className="top-bar " role="navigation">
                        <Row className="top-bar-content">
                            <Column className="padding-right-0 logo-box" small={6} medium={6} large={6}>
                                <img className="logo-icon" src={`${config.assetsPath}/images/achieve-logo.svg`} alt="" />
                            </Column>
                            <Column className="header-user-info padding-left-0" small={12} medium={6} large={6}>
                                <Column className="text-right padding-0 call-icon-box" small={12}>
                                    <div className="call-icon inline-block text-right">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="call-icon-svg" width="24" height="24" viewBox="0 0 25 25" fill="none">
                                            <g clip-path="url(#clip0_32310_93)">
                                                <path d="M15.5499 5.5C16.5267 5.69057 17.4243 6.16826 18.128 6.87194C18.8317 7.57561 19.3094 8.47326 19.4999 9.45M15.5499 1.5C17.5792 1.72544 19.4715 2.63417 20.9162 4.07701C22.3608 5.51984 23.2719 7.41101 23.4999 9.44M22.4999 17.42V20.42C22.5011 20.6985 22.444 20.9742 22.3324 21.2293C22.2209 21.4845 22.0572 21.7136 21.852 21.9019C21.6468 22.0901 21.4045 22.2335 21.1407 22.3227C20.8769 22.4119 20.5973 22.4451 20.3199 22.42C17.2428 22.0856 14.2869 21.0341 11.6899 19.35C9.27376 17.8147 7.22527 15.7662 5.68993 13.35C3.99991 10.7412 2.94818 7.77099 2.61993 4.68C2.59494 4.40347 2.62781 4.12476 2.71643 3.86162C2.80506 3.59849 2.9475 3.35669 3.1347 3.15162C3.32189 2.94655 3.54974 2.78271 3.80372 2.67052C4.05771 2.55833 4.33227 2.50026 4.60993 2.5H7.60993C8.09524 2.49522 8.56572 2.66708 8.93369 2.98353C9.30166 3.29999 9.54201 3.73945 9.60993 4.22C9.73656 5.18007 9.97138 6.12273 10.3099 7.03C10.4445 7.38792 10.4736 7.77691 10.3938 8.15088C10.3141 8.52485 10.1288 8.86811 9.85993 9.14L8.58993 10.41C10.0135 12.9135 12.0864 14.9864 14.5899 16.41L15.8599 15.14C16.1318 14.8711 16.4751 14.6858 16.8491 14.6061C17.223 14.5263 17.612 14.5555 17.9699 14.69C18.8772 15.0286 19.8199 15.2634 20.7799 15.39C21.2657 15.4585 21.7093 15.7032 22.0265 16.0775C22.3436 16.4518 22.5121 16.9296 22.4999 17.42Z" stroke="#3300FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_32310_93">
                                                <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div className="inline-block text-left"
                                         style={{ verticalAlign: 'top' }}>
                                        <span className="dusky-blue questions-callus font-14">Question? Call us at</span><br/>
                                        <a
                                            className="phone-header"
                                            href="tel:(877) 255-0465"
                                            id="header_phone"
                                            onClick={() => {
                                                utag().link({
                                                    click_id: 'header_phone',
                                                    click_text: '(877) 255-0465',
                                                    click_url: 'tel:(877) 255-0465',
                                                    tealium_event: 'link_click',
                                                });
                                            }}
                                        >
                                            (877) 255-0465
                                        </a>
                                    </div>
                                </Column>
                            </Column>
                        </Row>
                    </nav>
                </div>
            </div>
        )
    }
}
