import * as React from "react";
import { Component, Children } from "react";
import { isDefined } from "./utils";

export enum TooltipSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  FIT = "fit",
}
export enum TooltipPos {
  UP = "up",
  DOWN = "down",
  LEFT = "left",
  UP_LEFT = "up-left",
  RIGHT = "right",
  UP_RIGHT = "up-right",
  DOWN_LEFT = "down-left",
  DOWN_RIGHT = "down-right",
}

export interface TooltipProps {
  pos?: TooltipPos;
  size?: TooltipSize;
  visible?: boolean;
  content: string | Element;
  children?: any;
}

export class Tooltip extends Component<TooltipProps> {
  render() {
    let define = (obj) => {
      let object = {};
      for (let k in obj) {
        if (isDefined(obj[k])) {
          object[k] = obj[k];
        }
      }
      return object;
    };
    return Children.map(this.props.children, (child: any) =>
      React.cloneElement(child, {
        ...define({ "data-balloon-length": this.props.size }),
        ...define({
          "data-balloon-pos": this.props.pos ? this.props.pos : TooltipPos.UP,
        }),
        ...define({
          "data-balloon-visible": this.props.visible
            ? this.props.visible
            : void 0,
        }),
        ...define({ "data-balloon": this.props.content }),
      })
    );
  }
}
