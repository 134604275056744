import { State } from "./types/State";
import { applyMiddleware } from "redux";
import { combineReducers } from "redux";
import { compose } from "redux";
import { createStore } from "redux";
import data from "./reducers/data";
import view from "./reducers/view";
import browser from "./reducers/browser";
import token from "./reducers/token";
import error from "./reducers/error";
import ecm from "./reducers/ecm";
import { reducer as formReducer } from "redux-form";
import { actionTypes } from "redux-form";
import { createBackgroundMiddleware } from "./middlewares/background";
import { createTrackerMiddleware } from "./middlewares/tracker";
import { createLoggingMiddleware } from "./middlewares/logging";
import { createPersistEnhancer } from "./enhancers/persist";
import { createThunkMiddleware } from "./middlewares/thunk";
import { Api } from "./services/Api";
import { Tracker } from "./services/Tracker";
import { ActionType } from "./constants/ActionType";

export const initialState: State = {
  form: null,
  browser: {
    vt_session_id: null,
    session_id: null,
    browser_id: null,
    login_id: null,
  },
  ecm: {
    types: [
      {
        slug: "401k_statement",
        label: "401k Statement",
      },
      {
        slug: "adverse_action_notice",
        label: "Adverse Action Notification",
      },
      {
        slug: "award_letter",
        label: "Award Letter",
      },
      {
        slug: "bank_statement",
        label: "Bank Statement",
      },
      {
        slug: "child_support_alimony",
        label: "Child Support/Alimony",
      },
      {
        slug: "credit_report_pdf",
        label: "Credit Report PDF",
      },
      {
        slug: "credit_report_xml",
        label: "Credit Report XML",
      },
      {
        slug: "decision_logic",
        label: "Decision Logic",
      },
      {
        slug: "employment_offer_letter",
        label: "Employment Offer Letter",
      },
      {
        slug: "fdr_agreement",
        label: "FDR Agreement",
      },
      {
        slug: "finicity_voa",
        label: "Finicity VOA",
      },
      {
        slug: "finicity_voi",
        label: "Finicity VOI",
      },
      {
        slug: "form_1040",
        label: "Form 1040",
      },
      {
        slug: "home_equity_verification",
        label: "Home Equity Verification",
      },
      {
        slug: "income_calculator",
        label: "Income Calculator",
      },
      {
        slug: "loan_application",
        label: "Loan Application",
      },
      {
        slug: "paystub",
        label: "Paystub",
      },
      {
        slug: "power_of_attorney",
        label: "Power of Attorney",
      },
      {
        slug: "proof_of_address",
        label: "Proof of Address",
      },
      {
        slug: "proof_of_identity",
        label: "Proof of Identity",
      },
      {
        slug: "ptf_condition",
        label: "PTF Condition",
      },
      {
        slug: "signed_contract",
        label: "Signed Contract",
      },
      {
        slug: "third_party_authorization",
        label: "Third Party Authorization",
      },
      {
        slug: "verification_of_employment",
        label: "Verification of Employment",
      },
      {
        slug: "w2",
        label: "W2",
      },
    ],
  },
  token: null,
  data: null,
  error: {},
  view: {
    errorMessage: null,
    loading: false,
    incomeHelpModal: false,
    unauthorized: false,
    live_agent: false,
    metadata: {},
    iav_url: null,
    cts_loaded: false,
  },
};

export function configureStore(preloadedState?: any) {
  const { config } = window;
  const api = new Api();
  const tracker = new Tracker();

  const trackerMiddleware = createTrackerMiddleware(tracker);
  const loggerMiddleware = createLoggingMiddleware(api);
  const backgroundMiddleware = createBackgroundMiddleware(api);
  const thunk = createThunkMiddleware(api);

  const persistEnhancer = createPersistEnhancer({
    key: "redux-next",
    whitelist: ["browser", "token", "data", "ecm"],
  });
  const form = formReducer.plugin({
    Questions: (state, action) => {
      if (action.type === actionTypes.UNREGISTER_FIELD) {
        const {
          values: { [action.payload.name]: unregistered, ...values },
        } = state;
        return { ...state, values };
      }
      return state;
    },
  });
  const reducers = combineReducers({
    data,
    ecm,
    view,
    browser,
    token,
    error,
    form,
  });

  const composeEnhancers =
    process.env.NODE_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          name: "CPAPP",
          actionsBlacklist: ["REDUX_STORAGE_SAVE"],
        })
      : compose;

  const store = createStore(
    reducers,
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        trackerMiddleware,
        loggerMiddleware,
        backgroundMiddleware
      ),
      persistEnhancer
    )
  );

  api.setStore(store);
  tracker.setStore(store);

  store.dispatch({ type: ActionType.INITIALIZE });

  if (config.vt_enabled) {
    tracker.start().catch(console.error);
  }
  return store;
}
