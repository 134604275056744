import * as React from 'react';
import {Component, HorizontalAlignments, VerticalAlignments} from './component';
import {classNames, isDefined} from "./utils";
import {HTMLAttributes} from "react";

export interface RowProps extends HTMLAttributes<HTMLDivElement>{
    upOnSmall?:number,
    upOnMedium?:number,
    upOnLarge?:number,
    horizontalAlignment?:string,
    verticalAlignment?:string,
    unstackOnSmall?:boolean,
    unstackOnMedium?:boolean,
    unstackOnLarge?:boolean,
    collapseOnSmall?:boolean,
    collapseOnMedium?:boolean,
    collapseOnLarge?:boolean,
    uncollapseOnSmall?:boolean,
    uncollapseOnMedium?:boolean,
    uncollapseOnLarge?:boolean,
    isCollapsed?:boolean,
    isExpanded?:boolean,
    isColumn?:boolean
}

export class Row extends Component<RowProps>{
    rest(){
        const {
            upOnSmall,
            upOnMedium,
            upOnLarge,
            horizontalAlignment,
            verticalAlignment,
            unstackOnSmall,
            unstackOnMedium,
            unstackOnLarge,
            collapseOnSmall,
            collapseOnMedium,
            collapseOnLarge,
            uncollapseOnSmall,
            uncollapseOnMedium,
            uncollapseOnLarge,
            isCollapsed,
            isExpanded,
            isColumn,
            ...rest
        } = super.rest();
        return rest;
    }
    classNames():string{
        return classNames(
             'row',
            this.props.className,
            isDefined(this.props.upOnSmall) ? `small-up-${this.props.upOnSmall}` : null,
            isDefined(this.props.upOnMedium) ? `medium-up-${this.props.upOnMedium}` : null,
            isDefined(this.props.upOnLarge) ? `large-up-${this.props.upOnLarge}` : null,
            {
                'align-right': this.props.horizontalAlignment === HorizontalAlignments.RIGHT,
                'align-center': this.props.horizontalAlignment === HorizontalAlignments.CENTER,
                'align-justify': this.props.horizontalAlignment === HorizontalAlignments.JUSTIFY,
                'align-spaced': this.props.horizontalAlignment === HorizontalAlignments.SPACED,
                'align-top': this.props.verticalAlignment === VerticalAlignments.TOP,
                'align-middle': this.props.verticalAlignment === VerticalAlignments.MIDDLE,
                'align-bottom': this.props.verticalAlignment === VerticalAlignments.BOTTOM,
                'align-stretch': this.props.verticalAlignment === VerticalAlignments.STRETCH,
                'small-unstack': this.props.unstackOnSmall,
                'medium-unstack': this.props.unstackOnMedium,
                'large-unstack': this.props.unstackOnLarge,
                'small-collapse': this.props.collapseOnSmall,
                'medium-collapse': this.props.collapseOnMedium,
                'large-collapse': this.props.collapseOnLarge,
                'small-uncollapse': this.props.uncollapseOnSmall,
                'medium-uncollapse': this.props.uncollapseOnMedium,
                'large-uncollapse': this.props.uncollapseOnLarge,
                'collapse': this.props.isCollapsed,
                'expanded': this.props.isExpanded,
                'column': this.props.isColumn
            },
            this.generalClassNames()
        );
    }
    render(){
        return <div {...this.rest()} className={this.classNames()}/>
    }
}

export interface ColumnProps extends HTMLAttributes<HTMLDivElement>{
    small?:number,
    medium?:number,
    large?:number,
    offsetOnSmall?:number,
    offsetOnMedium?:number,
    offsetOnLarge?:number,
    pushOnSmall?:number,
    pushOnMedium?:number,
    pushOnLarge?:number,
    pullOnSmall?:number,
    pullOnMedium?:number,
    pullOnLarge?:number,
    orderOnSmall?:number,
    orderOnMedium?:number,
    orderOnLarge?:number,
    centerOnSmall?:boolean,
    centerOnMedium?:boolean,
    centerOnLarge?:boolean,
    uncenterOnSmall?:boolean,
    uncenterOnMedium?:boolean,
    uncenterOnLarge?:boolean,
    expandOnSmall?:boolean,
    expandOnMedium?:boolean,
    expandOnLarge?:boolean,
    isShrunk?:boolean,
    isLast?:boolean,
}

export class Column extends Component<ColumnProps>{
    classNames():string{
        return classNames(
            'column',
            this.props.className,
            this.props.small ? `small-${this.props.small}` : null,
            this.props.medium ? `medium-${this.props.medium}` : null,
            this.props.large ? `large-${this.props.large}` : null,
            isDefined(this.props.offsetOnSmall) ? `small-offset-${this.props.offsetOnSmall}` : null,
            isDefined(this.props.offsetOnMedium) ? `medium-offset-${this.props.offsetOnMedium}` : null,
            isDefined(this.props.offsetOnLarge) ? `large-offset-${this.props.offsetOnLarge}` : null,
            isDefined(this.props.pushOnSmall) ? `small-push-${this.props.pushOnSmall}` : null,
            isDefined(this.props.pushOnMedium) ? `medium-push-${this.props.pushOnMedium}` : null,
            isDefined(this.props.pushOnLarge) ? `large-push-${this.props.pushOnLarge}` : null,
            isDefined(this.props.pullOnSmall) ? `small-pull-${this.props.pullOnSmall}` : null,
            isDefined(this.props.pullOnMedium) ? `medium-pull-${this.props.pullOnMedium}` : null,
            isDefined(this.props.pullOnLarge) ? `large-pull-${this.props.pullOnLarge}` : null,
            isDefined(this.props.orderOnSmall) ? `small-order-${this.props.orderOnSmall}` : null,
            isDefined(this.props.orderOnMedium) ? `medium-order-${this.props.orderOnMedium}` : null,
            isDefined(this.props.orderOnLarge) ? `large-order-${this.props.orderOnLarge}` : null,
            {
                'small-centered': this.props.centerOnSmall,
                'medium-centered': this.props.centerOnMedium,
                'large-centered': this.props.centerOnLarge,
                'small-uncentered': this.props.uncenterOnSmall,
                'medium-uncentered': this.props.uncenterOnMedium,
                'large-uncentered': this.props.uncenterOnLarge,
                'small-expand': this.props.expandOnSmall,
                'medium-expand': this.props.expandOnMedium,
                'large-expand': this.props.expandOnLarge,
                'shrink': this.props.isShrunk,
                'end': this.props.isLast
            },
            this.generalClassNames()
        );
    }
    rest(){
        const {
            small,
            medium,
            large,
            offsetOnSmall,
            offsetOnMedium,
            offsetOnLarge,
            pushOnSmall,
            pushOnMedium,
            pushOnLarge,
            pullOnSmall,
            pullOnMedium,
            pullOnLarge,
            orderOnSmall,
            orderOnMedium,
            orderOnLarge,
            centerOnSmall,
            centerOnMedium,
            centerOnLarge,
            uncenterOnSmall,
            uncenterOnMedium,
            uncenterOnLarge,
            expandOnSmall,
            expandOnMedium,
            expandOnLarge,
            isShrunk,
            isLast,
            ...rest
        } = super.rest();
        return rest;
    }
    render(){
        return <div {...this.rest()} className={this.classNames()}/>;
    }
}