import {actionTypes} from 'redux-form';
import {EventType}   from "../constants/EventType";
import {ActionType}  from "../constants/ActionType";
import {Tracker}     from "../services/Tracker";
import {getRoles}    from "../selectors/token";
import {AuthRole}    from "../constants/AuthRole";

export const createTrackerMiddleware = (tracker:Tracker) => {
    return store => next => action => {
        if (!tracker.isStarted()) {
            return next(action);
        }
        if( getRoles(store.getState()).indexOf(AuthRole.LC) > -1 ){
            tracker.stop();
            return next(action);
        }
        try {
            switch (action.type) {
                case EventType.PAGEVIEW :
                case ActionType.LOCATION_CHANGE :
                    tracker.trackEvent({
                        event_action: EventType.PAGEVIEW,
                        event_page: typeof action.payload == 'string' ? action.payload : action.payload.pathname
                    }).catch(console.error);
                    break;
                case ActionType.CLICK_ELEMENT :
                    tracker.trackEvent({
                        ...action.payload,
                        event_action: EventType.CLICK,
                    }).catch(console.error);
                    break;
                case actionTypes.FOCUS:
                case actionTypes.BLUR: {
                    let name = action.meta.field;
                    let nodes: any = document.getElementsByName(name);
                    nodes && Array.prototype.slice.call(nodes, 0).forEach(node => {
                        tracker.trackEvent({
                            event_element: `input_${node.type}`,
                            event_action: actionTypes.BLUR === action.type ? 'blur' : 'focus',
                            event_element_name: name,
                            event_value: node.type === 'password' ? String(action.payload).trim().split('').map(() => '*').join('') : action.payload
                        }).catch(console.error);
                    });
                    break;
                }
                case actionTypes.CHANGE:
                    let name = action.meta.field;
                    let nodes: any = document.getElementsByName(name);
                    nodes && Array.prototype.slice.call(nodes, 0).forEach(node => {
                        switch (node.tagName) {
                            case "SELECT" :
                                tracker.trackEvent({
                                    event_action: 'change',
                                    event_element: 'select',
                                    event_element_name: name,
                                    event_value: action.payload
                                }).catch(console.error);
                                break;
                        }
                        switch (node.type) {
                            case 'file':
                                tracker.trackEvent({
                                    event_action: 'change',
                                    event_element: 'input_file',
                                    event_element_name: name,
                                    event_value: (action.payload[0] && action.payload[0].name) || ""
                                }).catch(console.error);
                                break;
                        }
                    });
                    break;
                default:
                    break;
            }
        } catch (e) {
            console.error(e);
            return next(action);
        }
        return next(action);
    };
};