// @ts-ignore
import * as React                                     from 'react';
import {Suspense, LazyExoticComponent, ComponentType} from "react";
import {PureComponent}                                from "react";
import {Route, RouteProps}                            from "react-router";
import {Loader}                                       from "../elements/Loader";

export interface LazyRouteProps extends RouteProps {
    component: LazyExoticComponent<ComponentType<any>>
}

export class LazyRoute extends PureComponent<LazyRouteProps> {

    renderRoute = props => {
        const Component = this.props.component;
        return (
            <Suspense fallback={<Loader/>}>
                <Component {...props}  />
            </Suspense>
        )
    };

    render() {
        const { component, render, ...props } = this.props;
        return <Route {...props} render={this.renderRoute}/>
    }
}
