import {ActionType}        from "../constants/ActionType";
import {Api}               from "../services/Api";
import {isMeaningfulError} from "../selectors/error";


export function createLoggingMiddleware(api: Api) {
    return ({ getState }) => next => action => {
        try {
            const error = action.payload;
            switch (action.type) {
                case ActionType.NOTIFY_ERROR :
                    if (getState().view.unauthorized) {
                        return next({ type: ActionType.NOTIFY_ERROR, payload: {} });
                    }
                    if (isMeaningfulError(error)) {
                        break;
                    }

                    if (error instanceof Error) {
                        console.error("[createLoggingMiddleware:1]:", action.payload.stack);
                        break;
                    }

                    if (typeof error == 'object' && error.message) {
                        console.error("[createLoggingMiddleware:2]:", error.message);
                        break;
                    }
                    if (typeof error == 'string') {
                        console.error("[createLoggingMiddleware:3]:", error);
                        break;
                    }
                    break;
            }
        } catch (e) {
            console.error(e);
        }
        return next(action);
    }
}
