import *  as React         from "react";
import {PureComponent}     from "react";
import { connect }         from "react-redux";
import { capitalize }      from "../../../helpers/utils/capitalize";
import { tealiumFlowComplete } from "../../../helpers/utils/tealium";
import * as dataSelector   from "../../../store/selectors/data";
import { State }           from "../../../store/types/State";
import {Column, Row}       from "../../../components/foundation";
import {Content}           from "../../../components/elements/Content";

export interface ThankYouProps {
    email: string;
}

export class ThankYouComp extends PureComponent<ThankYouProps>{
    componentWillMount() {
        tealiumFlowComplete(this.props.email);
    }

    componentDidMount(){
        process.nextTick(() =>window.scrollTo(0, 0))
    }

    render(){
        return (
            <Row>
                <Column medium={11}>
                    <Content className="thank-you">
                        <Row>
                            <Column small={12} medium={8} centerOnSmall={true} centerOnMedium={true}>
                                <div className="margin-top-60">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="48" viewBox="0 0 50 48">
                                        <path fill="none" fillRule="evenodd" stroke="#80BB41" strokeWidth="3" d="M2 26.667L17.333 45 27.88 29.867 48 1"/>
                                    </svg>
                                </div>
                                <div className="submitted">
                                    Completed!
                                </div>
                                <div className="margin-top-30 margin-bottom-100">
                                    <div className="text">Thank you for completing your document upload. Your application is now complete! We will reach out if anything else is needed!</div>
                                </div>
                            </Column>
                        </Row>
                    </Content>
                </Column>
            </Row>

        )
    }
}

export const ThankYou = connect((state: State) => {
    const applicant = dataSelector.getCurrentApplicant(state);
    return ({
        email: capitalize(applicant.email),
    })
})(ThankYouComp)
