import {ActionType}           from "../constants/ActionType";
import {Api}                  from "../services/Api";

export function createBackgroundMiddleware(api: Api) {
    return ({ getState, dispatch }) => next => action => {

        switch (action.type) {
            case ActionType.NOTIFY_SALESFORCE:
                api.postNotifySalesforce()
                    .catch(console.error);
                break;
        }
        return next(action);
    }
}