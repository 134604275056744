import {createSelector} from "reselect";
import {State}          from "../types/State";
import {Token}          from "../types/Token";

export const getToken = (state: State) => Object(state.token) as Token;

export const isAuthenticated = createSelector(
    getToken,
    (token) =>
        !!Object.keys(token).length &&
        !!token.lapstone_applicant_id &&
        !!token.access_token
);
export const isExpired = createSelector(
    getToken,
    isAuthenticated,
    (auth,isAuthenticated) => isAuthenticated ? new Date(auth.expires_at).getTime() < Date.now() : true
);
export const getRoles = createSelector(
    getToken,
    (auth) => (auth && auth.roles) || []
);
export const getPersonId = createSelector(
    getToken,
    (auth) => auth && auth.person_id
);