export enum ActionType {
    INITIALIZE                          = "@@CPAPP/INIT",
    LOCATION_CHANGE                     = 'LOCATION_CHANGE',
    SYNC_APPLICATION                    = 'SYNC_APPLICATION',
    UNAUTHORIZED                        = 'UNAUTHORIZED',
    SHOW_LOADER                         = 'SHOW_LOADER',
    HIDE_LOADER                         = 'HIDE_LOADER',
    NOTIFY_ERROR                        = 'NOTIFY_ERROR',
    UPDATE_APPLICANT_STATUS             = 'UPDATE_APPLICANT_STATUS',
    UPDATE_COAPPLICANT_STATUS           = 'UPDATE_COAPPLICANT_STATUS',
    SHOW_INCOME_HELP_MODAL              = 'SHOW_INCOME_HELP_MODAL',
    HIDE_INCOME_HELP_MODAL              = 'HIDE_INCOME_HELP_MODAL',
    UPDATE_ADDITIONAL_PROPERTIES        = 'UPDATE_ADDITIONAL_PROPERTIES',
    UPDATE_DOCUMENT_MANAGEMENT_SYSTEM   = 'UPDATE_DOCUMENT_MANAGEMENT_SYSTEM',
    UPDATE_APPLICATION                  = 'UPDATE_APPLICATION',
    CLICK_ELEMENT                       = 'CLICK_ELEMENT',
    INIT_LIVE_AGENT                     = 'INIT_LIVE_AGENT',
    REFRESH_TOKEN                       = 'REFRESH_TOKEN',
    LOGOUT                              = 'LOGOUT',
    ERROR_MESSAGE                       = 'ERROR_MESSAGE',
    ERROR                               = 'ERROR',
    UPDATE_METADATA                     = 'UPDATE_METADATA',
    SET_PERSON_ID                       = 'SET_PERSON_ID',
    OPEN_IAV                            = 'OPEN_IAV',
    CLOSE_IAV                           = 'CLOSE_IAV',
    NOTIFY_SALESFORCE                   = 'NOTIFY_SALESFORCE',
    CTS_LOADED                          = 'CTS_LOADED',
    SET_VT_ID                           = 'SET_VT_ID',
    INIT                                = 'INIT',
    DOC_TYPES                           = 'DOC_TYPES',
    SET_CURRENT_APPLICANT               = 'SET_CURRENT_APPLICANT'
}