import {createSelector}  from "reselect";
import {Application}     from "../types/models/Application";
import {State}           from "../types/State";
import {ApplicantType}   from "../constants/ApplicantType";
import {ApplicantStatus} from "../constants/ApplicantStatus";
import {AddressType}     from "../constants/AddressType";
import {Phone}           from "../types/models/Phone";
import {phoneFormat}     from "../../helpers/utils/phone";
import {getToken}        from "./token";

export const getApplication = (state: State): Application => state.data;
export const getApplicant = createSelector(
    getApplication,
    ({ applicant }) => applicant
);
export const getCoApplicant = createSelector(
    getApplication,
    ({ coapplicant }) => coapplicant
);
export const getCurrentApplicant = createSelector(
    getToken,
    getApplicant,
    getCoApplicant,
    ({ lapstone_applicant_id }, applicant, coapplicant) => {
        if (lapstone_applicant_id === applicant.applicant_id) {
            return applicant;
        }
        return coapplicant;
    }
);
export const isPrimaryApplicant = createSelector(
    getCurrentApplicant,
    ({ applicant_type }) => String(applicant_type).toUpperCase() === ApplicantType.PRIMARY
);
export const getPhone = createSelector(
    getCurrentApplicant,
    (state, type = void 0) => type,
    ({ phone, applicant_id }, type = void 0) => {
        if (Array.isArray(phone) && phone.length) {
            let instance: Phone = { ...phone[0] };
            if (typeof type !== 'undefined') {
                instance = { ...(phone.find(({ phone_type }) => phone_type === type) || {}) };
            }
            if (instance.phone_number) {
                instance.phone_number = phoneFormat(instance.phone_number);
            }
            return [instance];
        }
        return [{ phone_type: "Home", applicant_id }];
    }
);
export const getApplicantStatus = createSelector(
    getApplication,
    isPrimaryApplicant,
    ({ application_details }, isPrimary) => {
        if (isPrimary) {
            return application_details.primary_applicant_status;
        } else {
            return application_details.coapplicant_status;
        }
    }
);
export const getAdditionalProperties = createSelector(
    getApplication,
    ({ additional_properties }) => additional_properties || {}
);
export const isActive = createSelector(
    getApplication,
    ({ application_details: { application_state } }) => (String(application_state).toLowerCase() === 'active')
);
export const getCplusApplicationId = createSelector(
    getApplication,
    ({ loan_application: { salesforce_application_name } }) => {
        return String(salesforce_application_name).replace(/\D/g, "")
    }
);
export const getLoanApplicationId = createSelector(
    getApplication,
    ({ loan_application: { loan_application_id } }) => loan_application_id
);
export const getLanguage = createSelector(
    getApplication,
    ({ loan_application: { language } }) => language
);
export const getSalesforceApplicationId = createSelector(
    getApplication,
    ({ loan_application: { salesforce_application_id } }) => salesforce_application_id
);
export const isMissingDocs = createSelector(
    getApplicantStatus,
    (app_status) => (
        app_status === ApplicantStatus.MISSING_DOCS
    )
);
export const isMissingDocsIav = createSelector(
    getApplicantStatus,
    (app_status) => (
        app_status === ApplicantStatus.MISSING_DOCS_IAV
    )
);
export const isApplicationAndDocsReceived = createSelector(
    getApplicantStatus,
    (app_status) => (
        app_status === ApplicantStatus.MISSING_DOCS_IAV_AND_MANUAL ||
        app_status === ApplicantStatus.APPLICATION_AND_DOCS_RECEIVED ||
        app_status === ApplicantStatus.APPLICATION_AND_DOCS_RECEIVED_IAV ||
        app_status === ApplicantStatus.APPLICATION_AND_DOCS_RECEIVED_IAV_AND_MANUAL
    )
);
export const isAppIncomplete = createSelector(
    getApplicantStatus,
    (app_status) => (
        !app_status || app_status === ApplicantStatus.NO_LOGIN || app_status === ApplicantStatus.APP_INCOMPLETE
    )
);
export const getIavDetails = createSelector(
    getApplication,
    ({ iav_details }) => iav_details
);
export const hasApplicantIavDetails = createSelector(
    getLoanApplicationId,
    getIavDetails,
    getCurrentApplicant,
    (loan_application_id, iav_details, applicant) => (iav_details || [])
        .filter(detail => detail.loan_application_id === loan_application_id && detail.applicant_id === applicant.applicant_id).length > 0
);
export const isIavAvailable = createSelector(
    getApplication,
    ({ loan_application: { funding_source } }) => {
        return window.config.finicity_available_banks.includes(funding_source)
    }
);
export const isDocumentsProvided = createSelector(
    isPrimaryApplicant,
    getAdditionalProperties,
    getApplication,
    (isPrimaryApplicant, { applicant_documents_provided, coapplicant_documents_provided }, { application_details: { primary_app_over_phone, coapplicant_app_over_phone } }) =>
        isPrimaryApplicant ? primary_app_over_phone === 'true' || !!applicant_documents_provided : coapplicant_app_over_phone === 'true' || !!coapplicant_documents_provided
);
export const getAddress = createSelector(
    getCurrentApplicant,
    ({ address, applicant_id }) => {
        if (Array.isArray(address) && address.length === 1) {
            address[0].address_type = AddressType.BOTH;
            return address;
        }
        if (Array.isArray(address) && address.length === 2) {
            let physical = address.find(a => String(a.address_type).toUpperCase() === AddressType.PHYSICAL);
            if (!physical) {
                address[0].address_type = AddressType.PHYSICAL;
                physical = address[0];
            }
            let mailing = address.find(a => String(a.address_type).toUpperCase() === AddressType.MAILING);
            if (!mailing) {
                address[1].address_type = AddressType.MAILING;
                mailing = address[1];
            }
            return [physical, mailing];
        }
        return address || [{ address_type: AddressType.BOTH, applicant_id }]
    }
);