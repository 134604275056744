import * as React from "react";
import { PureComponent } from "react";
import { classNames } from "../foundation";

export class Content extends PureComponent<{
  className?: string;
  center?: boolean;
  children: any;
}> {
  render() {
    const { center = true } = this.props;
    return (
      <div
        className={classNames(
          "white-board flow-content",
          { "text-center": center },
          this.props.className
        )}
      >
        {this.props.children}
      </div>
    );
  }
}
