import crypto from 'crypto'

//const US_COUNTRY_CODE = 1

export const hashEmail = (email) => {
    if (!email) return null

    return crypto
        .createHash('sha256')
        .update(email.trim().toLowerCase())
        .digest('hex')
}
