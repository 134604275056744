import * as React from "react";
import { PureComponent } from "react";

export class Footer extends PureComponent {
  render() {
    const { config } = window;
    return (
      <div className="apply-footer">
        <div className="footer-box">
          <div>
            <a
              id="privacy"
              className="privacy-links privacy-links-margin-left privacy-links-margin-right"
              rel="noopener noreferrer"
              href="https://www.achieve.com/policies#Privacy-Policy"
              target="_blank"
            >
              Privacy
            </a>
          </div>
          <div>©2023 Achieve Personal Loans, All rights reserved.</div>

          <div>
            All loans available through Achieve Personal Loans are made by Cross
            River Bank (a New Jersey state chartered commercial bank) or
            Pathward®, N.A. (Equal Housing Lenders). All loan and rate terms are
            subject to eligibility restrictions, application review, loan
            amount, loan term, lender approval, and credit usage. Eligibility
            for a loan is not guaranteed. Loans are not available in all states
            - please call an Acceleration Loan representative for further
            details. Achieve Personal Loans is not a lender.
            <br/><br/> IMPORTANT
            INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT<br/> To help the
            government fight the funding of terrorism and money laundering
            activities, Federal law requires all financial institutions to
            obtain, verify, and record information that identifies each person
            who opens an account. What this means for you: When you open an
            account, we will ask for your name, address, date of birth, and
            other information that will allow us to identify you. We may also
            ask to see your driver's license or other identifying documents.
          </div>
          <div className="footer-images">
            <img
              src={`${config.assetsPath}/images/footer/equal-housing-lender.png`}
              alt=""
            />
            <img
              src={`${config.assetsPath}/images/footer/bbb-rating.png`}
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }
}
