import * as React from "react";
import { createPortal } from "react-dom";
import { Component } from "./component";
import { Component as ReactComponent } from "react";
import { classNames, isDefined } from "./utils";
import { HTMLAttributes } from "react";

export interface RevealProps extends HTMLAttributes<HTMLDivElement> {
  isTiny?: boolean;
  isSmall?: boolean;
  isLarge?: boolean;
  isFullscreen?: boolean;
  onClose?: (e) => void;
  showCloseButton?: boolean;
  close?: boolean;
  closeIcon?: any;
  closeProps?: any;
  closeOnBackgroundClick?: boolean;
  children?: any;
}

export class Portal extends ReactComponent<{
  onClickBackdrop?: (e) => void;
  children?: any;
}> {
  private el: HTMLElement;
  private backdrop: HTMLElement;
  constructor(props, context?) {
    super(props, context);
    this.el = document.createElement("div");
    this.backdrop = document.createElement("div");
    this.backdrop.className = "reveal-modal-bg";
    this.backdrop.style.display = "block";
    this.backdrop.addEventListener("click", this.onBackdrop);
  }
  onBackdrop = (e) => {
    this.props.onClickBackdrop(e);
  };
  componentDidMount() {
    document.body.appendChild(this.el);
    document.body.appendChild(this.backdrop);
  }
  componentWillUnmount() {
    this.backdrop.removeEventListener("click", this.onBackdrop);
    document.body.removeChild(this.el);
    document.body.removeChild(this.backdrop);
  }
  render() {
    return createPortal(this.props.children, this.el);
  }
}

export class Reveal extends Component<RevealProps, { close: boolean }> {
  static defaultProps = {
    closeOnBackgroundClick: true,
    showCloseButton: true,
  };
  state = {
    close: this.props.close || false,
  };
  handleClose = (e) => {
    this.setState({
      close: true,
    });
  };
  handleBackdrop = (e) => {
    let { onClose = this.handleClose } = this.props;
    if (this.props.closeOnBackgroundClick) {
      onClose(e);
    }
  };
  componentWillReceiveProps(nextProps) {
    if (isDefined(nextProps.close)) {
      this.setState({
        close: nextProps.close,
      });
    }
  }
  render() {
    const className = classNames(
      "reveal-modal",
      this.props.className,
      {
        tiny: this.props.isTiny,
        small: this.props.isSmall,
        large: this.props.isLarge,
        full: this.props.isFullscreen,
      },
      this.generalClassNames()
    );
    const {
      isTiny,
      isSmall,
      isLarge,
      isFullscreen,
      closeOnBackgroundClick,
      showCloseButton,
      close,
      closeProps = {},
      onClose = this.handleClose,
      closeIcon = <i className="freedom-close" />,
      ...rest
    } = this.rest();
    let style = {
      opacity: 1,
      visibility: "visible",
      display: "block",
      position: "fixed",
    };
    return (
      !this.state.close && (
        <Portal onClickBackdrop={this.handleBackdrop}>
          <div {...rest} className={className} style={style}>
            {this.props.showCloseButton && (
              <a
                {...closeProps}
                className="close-reveal-modal"
                onClick={onClose}
              >
                {closeIcon}
              </a>
            )}
            {this.props.children}
          </div>
        </Portal>
      )
    );
  }
}
