import {Folder}          from "../store/types/models/Folder";
import {Applicant}       from "../store/types/models/Applicant";
import {scrollIntoError} from "./utils/scrollIntoError";
import {FolderType}      from "../store/constants/FolderType";

export const createEmptyFolder = ({ internal_document = 'false',...rest }:Partial<Folder> = {}) => ({
    internal_document,
    folder_status: "New Request",
    folder_required: 'true',
    ...rest
});
export const folderType = (folder_type) => (
    String(folder_type || "").split(' - ')[0].trim().toLowerCase()
);
export const isFoldersEqual = (f1,f2:FolderType|string)=>{
    return folderType(f1).toLowerCase() === f2.toLowerCase();
};
export function serializeIncome(data: Applicant | any) {
    let frequencies = {
        "annually": 1,
        "monthly": 12,
        "semi-monthly": 24,
        "bi-weekly": 26,
        "weekly": 52
    };
    if (!data.additional_income) {
        data.additional_income = 0;
    }
    const annual_income =
        frequencies[data.gross_income_frequency] * data.gross_income +
        (frequencies[data.additional_income_frequency] || 0) * data.additional_income;
    data.annual_income = Number(annual_income.toFixed(2));
    return data;
}
export function onSubmitFail() {
    setTimeout(scrollIntoError)
}
export function virtualPageView() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'virtual-page-view',
        'pageURL': window.location.href,
        'pageTitle': document.title
    });
    return window.dataLayer
}