import * as React                  from "react";
import {PureComponent}             from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import {RouteComponentProps}       from "react-router";
import {Loader}                    from "../../components/elements/Loader";
import ErrorAlert                  from "../../components/elements/ErrorAlert";
import {Layout}                    from "../../components/sections/Layout";
import {connect}                   from "react-redux";
import {State}                     from "../../store/types/State";
import {pageView}                  from '../../store/actions/view';
import {notifyError}               from '../../store/actions/error';
import * as errorSelector          from '../../store/selectors/error';
import {initLiveAgent}             from "../../store/actions/esw";
import {virtualPageView}           from "../../helpers/functions";
import {Content}                   from "../../components/elements/Content";
import {TextMessage}               from "../../components/elements/TextMessage";


export interface AppProps extends RouteComponentProps<{}> {
    readonly loading: boolean;
    readonly hasError: boolean;
    readonly notifyError: typeof notifyError;
    readonly pageView: typeof pageView;
    readonly initLiveAgent: typeof initLiveAgent;
}

class App extends PureComponent<AppProps> {

    componentWillMount() {
        this.props.initLiveAgent();
        this.props.pageView();
        this.props.history.listen((location) => {
            this.props.pageView(location);
            virtualPageView();
        })
    }

    componentDidCatch(error: Error) {
        this.props.notifyError(error);
    }

    render() {
        const { match: { path }, loading, hasError } = this.props;
        return (
            <Layout className="apply">
                <Switch>
                    <Route path={path} render={() => (
                        <Content>
                            <TextMessage level={'info'} className='margin-bottom-30' message={"The service is currently under maintenance. Please come back later to complete your Acceleration Loan application."}/>
                        </Content>
                    )}/>
                </Switch>
                {loading && <Loader/>}
                {hasError && <ErrorAlert/>}
            </Layout>
        )
    }
}

export default withRouter(connect((state: State) => ({
    loading: state.view.loading,
    hasError: errorSelector.hasError(state)
}), { notifyError, pageView, initLiveAgent })(App));
