import {Component as ReactComponent, DOMAttributes} from 'react';

export enum SIZE {
    SMALL   = 'small',
    MEDIUM  = 'medium',
    LARGE   = 'large',
    XLARGE  = 'xlarge',
    XXLARGE = 'xxlarge'
}
export enum SIZES  {
    SMALL   = 'small',
    MEDIUM  = 'medium',
    LARGE   = 'large',
    XLARGE  = 'xlarge',
    XXLARGE = 'xxlarge',
    ALL     = 'all'
}
export enum FloatTypes  {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right'
}
export enum HorizontalAlignments {
    CENTER  = 'center',
    RIGHT   = 'right',
    JUSTIFY = 'justify',
    SPACED  = 'spaced'
}
export enum VerticalAlignments  {
    TOP     = 'top',
    MIDDLE  = 'middle',
    BOTTOM  = 'bottom',
    STRETCH = 'stretch'
}

export interface Props {
    showFor?: SIZE.MEDIUM | SIZE.LARGE,
    showOnlyFor?:  SIZE,
    hideFor?: SIZE.MEDIUM | SIZE.LARGE,
    hideOnlyFor?: SIZE,
    isHidden?: boolean,
    isInvisible?: boolean,
    showForLandscape?: boolean,
    showForPortrait?: boolean,
    showForSr?: boolean,
    showOnFocus?: boolean,
    isClearfix?: boolean,
    float?: FloatTypes
}


export class Component<P = {}, S = {}> extends ReactComponent<P&Props&DOMAttributes<{}>>{
    protected generalClassNames() {
        return {
            'show-for-medium': this.props.showFor === SIZE.MEDIUM,
            'show-for-large': this.props.showFor === SIZE.LARGE,
            'show-for-small-only': this.props.showOnlyFor === SIZE.SMALL,
            'show-for-medium-only': this.props.showOnlyFor === SIZE.MEDIUM,
            'show-for-large-only': this.props.showOnlyFor === SIZE.LARGE,
            'hide-for-medium': this.props.hideFor === SIZE.MEDIUM,
            'hide-for-large': this.props.hideFor === SIZE.LARGE,
            'hide-for-small-only': this.props.hideOnlyFor === SIZE.SMALL,
            'hide-for-medium-only': this.props.hideOnlyFor === SIZE.MEDIUM,
            'hide-for-large-only': this.props.hideOnlyFor === SIZE.LARGE,
            'hide': this.props.isHidden,
            'invisible': this.props.isInvisible,
            'show-for-landscape': this.props.showForLandscape,
            'show-for-portrait': this.props.showForPortrait,
            'show-for-sr': this.props.showForSr,
            'show-on-focus': this.props.showOnFocus,
            'clearfix': this.props.isClearfix,
            'float-left': this.props.float === FloatTypes.LEFT,
            'float-center': this.props.float === FloatTypes.CENTER,
            'float-right': this.props.float === FloatTypes.RIGHT
        };
    }
    protected rest(){
        const {
            showFor,
            showOnlyFor,
            hideFor,
            hideOnlyFor,
            isHidden,
            isInvisible,
            showForLandscape,
            showForPortrait,
            showForSr,
            showOnFocus,
            isClearfix,
            float,
            ...rest
        } = this.props as any;
        return rest;
    }
}